.modal, .modalNoChrome {
  position:absolute;
  z-index: 3;
  width: 100%;
  background: var(--pre-bg-color, #fff);
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -webkit-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -moz-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  top: 0;
  left: 0;
  max-width: 1200px;
}

.modalContainer {
  position:absolute;
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.modalContainer > div {
  position: relative;
  max-width: 500px;
}

.modalPadding {
  margin: 20px;
}

.modal::before{
  background: var(--pre-bg-color, url("./../../images/Color-Strip.png") repeat);
  height:8px;
  width: 100%;
  content: '';
  position:absolute;
  top:0;
  left:0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease .5s;
  -moz-animation: fadeIn ease .5s;
  -o-animation: fadeIn ease .5s;
  -ms-animation: fadeIn ease .5s;
}

.modal-open::after{
  content: "";
  height: 100%;
  width: 100%;
  min-height: 100%;
  position:fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--main-base-white, rgba(252, 251, 250, 0.7));
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease .5s;
  -moz-animation: fadeIn ease .5s;
  -o-animation: fadeIn ease .5s;
  -ms-animation: fadeIn ease .5s;
}

.modal h2 {
  color: var(--header-color, #4C825C);
  font-size: 20px;
  font-weight: 700;
}

.modal .content {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

button {
  color: var(--link-color, #fcfbfa);
  font-size: 14px;
  height: 44px;
  min-width: 88px;
  margin: 32px 0;
  padding: 13px 20px 15px;
  padding: 13px 16px 15px;
  border-radius: 4px;
  border: none;
}

button:hover {
  background: var(--link-hover-color, #312d2a82);
}

.actions .solid {
  float:right;
}

.sticky {
  position: sticky;
  top: 0.25em;
  z-index: 9;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
