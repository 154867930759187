.loader {
    font-size: 6px;
    position: relative;
}
.loader.loader-sm {
    font-size: 3px;
    float: left;
    margin-right: 5px;
}
.loader.loader-md {
    font-size: 6px;
}
.loader.loader-lg {
    font-size: 12px;
}

.loader .loader-well,.loader-track {
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 8em;
    height: 8em;
    border: 1em solid var(--pre-bg-color, #EDE9E7);
    border-radius: 50%;
}

.loader .loader-track {
    top: 0;
    position: absolute;
    animation: loader 1.2s linear infinite;
    transform: rotate(45deg);
    border-color: var(--main-bg-success, #4C825C) transparent transparent transparent;
}

@keyframes loader {
    0% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(405deg);
    }
}
