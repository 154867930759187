/**  Button Component **/
button {
  color: var(--html-txt-color, #fcfbfa);
  font-size: 14px;
  height: 44px;
  min-width: 88px;
  margin: 32px 0;
  padding: 13px 20px 15px;
  padding: 13px 16px 15px;
  border-radius: 4px;
  border: 2px solid var(--link-color, #312D2A);
}

button:hover {
  background: var(--link-hover-color, #fcfbfa);
  cursor: pointer;
}

button.callToAction {
  background: var(--main-bg-success, #6a9d67);
}

button.solid {
  background: var(--main-button-bg-color, #312D2A);
  color: var(--main-base-white, #FFF);
  border: 2px solid transparent;
}

button.solid:disabled {
  background: var(--main-button-bg-color-disabled, rgba(22,21,19,0.05));
  color: var(--pre-bg-color, rgba(22, 21, 19, 0.3));
  border: none;
}

button.outline {
  border: 2px solid var(--link-color, #312D2A);
  background: var(--main-base-white, #FFF);
  color: var(--link-color, #312D2A);
}
