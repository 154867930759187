@import "../../App.css";

.progressContainer {
  border-radius: 4px;
  margin: 25px 0;
  position:relative;
  padding: 32px;
  box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -webkit-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -moz-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  overflow: auto;
  background-color: var(--main-side-color, #467553);
  color: var(--table-text-color, #FFFFFF);
}

.progressContainer::before{
  background: var(--main-side-color, url("../../images/Color-Strip.png"));
  height:8px;
  width: 100%;
  content: '';
  position:absolute;
  top:0;
  left:0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.progressContent {
  background: var(--html-txt-color, #fcfbfa);
  border-top: 1px solid rgba(22, 21, 19, 0.1);
  width: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  min-height:50px;
  margin-top:50px;
  padding-bottom:25px;
}

.progressContainer h2.title6 {
  margin-top: 0;
}

.progressContainer p.subinfo {
  font-size: 0.9em;
}

.progressContainer dl {
  margin: 20px 32px 0;
}

.progressContent dt {
  font-size: 12px;
  line-height: 16px;
  color: rgba(22, 21, 19, 0.6);
}

.progressContainer dl a {
  float: right;
  color: rgba(22, 21, 19, 0.6)
}

.progressContent dd {
  font-size: 16px;
  line-height: 20px;
  color: var(--header-color, #161513);
  margin-bottom: 10px;
  margin-inline-start: 0;
}


@media only screen and (max-width: 480px) {
  .progressContainer {
    margin: 0 0 8px;
    border-radius: 0;
  }

  .col-xs-12.sidebar {
    padding: 0;
  }
}
