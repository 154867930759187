@import "../../App.css";

.promoContainer {
  background-color: var(--main-side-color, #467553);
  border-radius: 4px;
  color: var(--table-text-color, #FFFFFF);
  margin-top: 25px;
  padding: 0px 32px 32px 32px;
  box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -webkit-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -moz-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
}
.promoContainer > div {
  display: inline-block;
}

.promoContainer h2 {
  font-size: 1.625em;
  font-weight:700;
  line-height: 40px;
}

.promoContainer p {
  line-height: 1.4em;
}

@media only screen and (max-width: 480px) {
  .promoContainer {
    display:none;
  }
}