/*! FONT - ORACLESANS FONT */
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-Lt.woff2") format("woff2");
  font-weight:200;
  font-style:normal
}
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-LtIt.woff2") format("woff2");
  font-weight:200;
  font-style:italic
}

@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-It.woff2") format("woff2");
  font-weight:400;
  font-style:italic
}
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-SBd.woff2") format("woff2");
  font-weight:500;font-style:normal
}
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-SBdIt.woff2") format("woff2");
  font-weight:500;
  font-style:italic
}
@font-face{font-display:fallback;font-family:"Oracle Sans";src:url("./fonts/OracleSans-Bd.woff2") format("woff2");font-weight:700;font-style:normal}
@font-face{font-display:fallback;font-family:"Oracle Sans";src:url("./fonts/OracleSans-BdIt.woff2") format("woff2");font-weight:700;font-style:italic}
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-XBd.woff2") format("woff2");
  font-weight:800;
  font-style:normal
}

@font-face{
  font-display:fallback;
  font-family:"Oracle Sans Condensed";
  src:url("./fonts/OracleSans-Bd.woff2") format("woff2");
  font-weight:500;font-style:normal}

@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-Rg.woff2") format("woff2");
  font-weight:500;
  font-style:normal
}
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-SBd.woff2") format("woff2");
  font-weight:600;
  font-style:normal
}
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-Bd.woff2") format("woff2");
  font-weight:700;
  font-style:normal
}
@font-face {
  font-display: fallback;
  font-family: "Oracle Sans";
  src: url("./fonts/OracleSans-XBd.woff2") format("woff");
}
@font-face{
  font-display:fallback;
  font-family:"Oracle Sans";
  src:url("./fonts/OracleSans-Rg.woff2") format("woff2");
  font-weight:400;
  font-style:normal
}
body,input{
  font-family:"Oracle Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight:400;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  color: var(--html-txt-color, #100f0e);
}

h1,h2,h3,h4,h5,h6{
  font-weight:500;
  color: var(--header-color) !important;
}

a {
  color: var(--db-link-color);
}
a:visited {
  color: var(--link-visited);
}

i{font-style:italic}
/*! FONT - REDWOOD ICONS */
 @font-face{font-family:'redwoodicons';src:url("./fonts/ojuxIconFont_Rg.ttf") format("truetype")}
/** Oracle Font Definitions **/

body {
  background: #F8F7F4;
}

.body2 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.title4 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 800;
}

.title6 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.title7 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.title8 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

/**  Errors **/
.error {
  margin-top:5px;
  margin-bottom: 5px;
  clear: both;
}

.clear {
  clear: both;
}

/**  Icons **/

.icon {
  width: 20px;
  display: inline-block;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.infoIcon:before {
  font-family: redwoodicons;
  content: '\f2a6';
  width: 10px;
  text-align: center;
}

.errorIcon:before {
  font-family: redwoodicons;
  content: '\f242';
  text-align: center;
  color: var(--main-bg-danger, #BC513E);
}


/**  Header **/
.brandingBar {
  height:60px;
  overflow:none;
}

.brandingHeaderColor {
  background: var(--header-base-color, #312D2A);
  color: var(--main-base-white) !important;
}

.oLogo{
  background-position:center;
  background-repeat:no-repeat;
  background-size:36px;
  margin: 8px 0 8px 51px;
  float:left;
  transform: translate(-4px, 2px);
}

.oLogo:hover{cursor:pointer}

.oLogoWords {
  margin: 18px 10px 18px;
  color: var(--main-base-white, #fcfbfa);
  display: inline-block;
}

/**  Body **/
main {
  margin: 0 auto;
  max-width: 1240px;
}

.mainContainer {
  position:relative;
}

div#root > div {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--main-base-color);
}

main#main {
  flex: 1;
}

div.mainContainer > div > div.modal {
  min-width: 70vw;
  margin-left: -30vw;
}
