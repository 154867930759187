/**  Footer **/
footer {
    max-width: 1240px;
    margin: 0 auto;
    overflow: hidden;
}

div#root > div > footer > div.row {
    justify-content: center;
    align-items: center;
    max-width: 1240px;
    margin: 25px auto;
    width: -webkit-calc(100vw - 50px);
    width:    -moz-calc(100vw - 50px);
    width:         calc(100vw - 50px);
}

footer a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: var(--header-base-color, #3F3A36);
    text-decoration: none;
}

footer .copyright {
    text-align: right;
}
footer .terms {
    text-align: right;
}
  
@media only screen and (max-width: 480px) {
    footer {
        margin: 0 0 10px;
    }
    div#root > div > footer > div.row {
        max-width: 480px;
        margin: 10px auto;
    }
    footer .col-xs-12, footer .col-xs-12 a {
        text-align: left;
        font-size: 10px;
        line-height: 20px;
    }
    footer .pipe {
        display: none;
    }
    footer .privacy {
        font-size: 10px;
    }
    .oLogo {
        margin-left:10px;
    }
    .oLogoWords {
        margin-left:11px;
    }
}

@media only screen and (max-width: 876px) {
    footer .col-sm-4, footer .col-sm-3  {
        padding: 0;
    }

    footer .copyright, footer .privacy, footer .powered-by {
        text-align: center !important;
    }

    footer .pipe {
        display: none;
    }
}
  
@media only screen and (max-width: 785px) {
    div#root > div > footer > div.row {
        max-width: 785px;
    }
    footer .col-sm-4, footer .col-sm-4 a, footer .col-sm-3, footer .col-sm-3 a  {
        text-align: left;
        line-height: 20px;
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
}