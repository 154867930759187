.waitingRoomContainer {
    background-color: var(--main-base-white, #ffffff);
    border-radius: 4px;
    margin: 48px auto;
    padding-bottom: 1px;
}
.waitingRoomHeader {
    background: var(--main-side-color, url('./../../images/Rectangle-683.png')) repeat;
    background-color: var(--main-side-color, #967c56);
    border-radius: 8px 8px 0 0;
    height:auto;
    padding: 79px 0 34px;
}

.waitingRoomLoadingGraphic {
    background: url('./../../images/OCI-O-transition-loader.gif') center no-repeat;
    width: 508px;
    height: 315px;
    margin: 0 auto;
}

.waitingRoomMessage, .waitingRoomHelp {
    text-align: center;
    color: var(--html-txt-color, rgba(251, 249, 248, 0.9));
}
.waitingRoomMessage {
    margin: 27px 0 74px;
    font-size: 28px;
}

.waitingRoomHelp {
    font-size: 16px;
}

.waitingRoomHelp a {
    color: var(--link-color, #F1CD71);
}

.waitingRoomCarousel {
    width: 80%;
    margin: 75px auto;
}
.waitingRoomCarousel hr {
    border-top: 4px solid var(--header-base-color, #F1CD71);
    width: 32px;
    margin: 14px 0 25px;
}

.waitingRoomCarousel h2 {
    font-size: 36px;
    color: var(--header-color, #161513);
    font-family: Georgia, Serif;
}