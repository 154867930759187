@import "../../../App.css";
@import 'react-phone-input-2/lib/style.css';

.contentContainer {
  border-radius: 8px 8px 0 0;
  background: var(--pre-bg-color, #FFF);
  min-height: 500px;
  position:relative;
  box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -webkit-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -moz-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
}

.upperContentContainer {
  margin: 25px 0 0;
  padding: 32px 36px 0;
}

.contentContainer h1 {
  font-size: 28px;
  font-weight: 800;
  margin-top: 0;
}

.contentContainer .row {
  margin-right: -1rem;
  margin-left: -1rem;
}

.contentContainer::before{
  background:var(--pre-bg-color, url("../../../images/Color-Strip1.png"));
  height:8px;
  width: 100%;
  content: '';
  position:absolute;
  top:0;
  left:0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.termsComponent {
  padding: 32px 36px 32px;
}

.customer-type-select {
  margin-top: 24px;
}
.customer-type-select > div > .row {
  margin-top: 24px;
}

.row.phone {
  margin-top: 24px;
}

.react-tel-input {
  border: 1px solid rgba(22,21,19,0.3);
  border-radius: 4px;
  height: 56px
}

.react-tel-input .form-control {
  width: 100%;
  font-size: 16px;
  padding-left: 75px;
  border: none !important;
  margin-top:20px !important;
}

.react-tel-input .flag-dropdown {
  border: none;
  background-color: #fff;
}

.react-tel-input .selected-flag .flag {
  margin: -5px 10px 0;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 40%;
  margin-left: 8px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #555;
}

.wrapper {
  min-height: 500px;
}

@media only screen and (max-width: 480px) {
  .contentContainer, 
  .progressContainer {
    min-width:295px;
  }
  .contentContainer::before{
    background:none;
    height:0;
  }
  .upperContentContainer {
    padding: 32px 24px 0;
    margin-top: 10px;
  }

  .col-xs-12.mainContainer  {
    padding-left:8px;
    padding-right:8px;
  }
}
