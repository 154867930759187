@import "../../App.css";

.terms {
  color: var(--header-color, #4A4A4A);
  font-weight: 400;
}
.terms a {
  color: var(--link-color, #446672);
}

.margin-top-10 {
  margin-top: 10px;
}

.heading {
  color: var(--header-color, #1A1816);
}

.termsComponent {
  background: var(--pre-bg-color, #F5F4F2);
  padding: 32px 36px 32px;
  margin: 36px 0;
}

