/**  Icons **/

.icon {
    font-family: redwoodicons;
    content: '\f2a6';
    width: 20px;
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
    text-align: center;
}

.info:before {
    content: '\f2a6';
    width: 10px;
}

.error:before {
    content: '\f242';
    color: var(--main-bg-danger, #BC513E);
}

.greenCheck:before {
    content: '\f1bb';
    color: var(--main-bg-success, #5F7D4F);
}

.grayCheck:before {
    content: '\f1bb';
    color: var(--main-bg-slateGray, #DFDCD8);
}

.redCheck:before {
    font-family: redwoodicons;
    content: '\f1bb';
    text-align: center;
    color: var(--main-bg-danger, #BC513E);
}

.close:before {
    content: '\f1e2';
}

.warning:before {
    content: '\f3a3';
    color: var(--main-bg-warning, rgb(212, 158, 88));
}

.accordionArrowUp:before {
    content: '\f1d1';
    color: var(--html-txt-color, #100F0E);
}
  
.accordionArrowDown:before {
    content: '\f1cb';
    color: var(--html-txt-color, #100F0E);
}
