.rw-checkbox {
  display: block;
  position: relative;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 28px;
  margin-left: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rw-checkbox input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid black;
  border-radius: 4px;
}

.rw-checkbox input:focus ~ .checkmark {
  outline: auto 2px Highlight;
}

.rw-checkbox input:checked ~ .checkmark {
  background-color: black;
}
.rw-checkbox input[disabled] ~ .checkmark {
  border: 1px solid var(--main-bg-slateGray, #B4B3B2);
}

.rw-checkbox input[disabled] ~ .checkmark ~ span {
  color: var(--main-bg-slateGray, #B4B3B2);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.rw-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.rw-checkbox .checkmark:after {
  left: 5px;
  width: 5px;
  height: 9px;
  border: solid var(--main-base-white, white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
