.ty-wrapper {
    min-height: 500px;
}
.ty-wrapper > .modalContainer > div.modal {
    max-width: 80%;
    height: inherit;
}

.ty-wrapper > .modalContainer > div.modal > div.modalPadding {
    margin: 4rem 8rem;
}

.ty-wrapper > .modalContainer > div.modal > div.modalPadding  > div.content{
    line-height: inherit;
}

.ty-wrapper hr.thankyou {
    border-top: 4px solid var(--header-base-color, #F1CD71);
    width: 32px;
    margin: 14px 0 25px;
}
.ty-wrapper .modal h2 {
    color: var(--header-color, #161513);
    font-family: Georgia, Serif;
}

.ty-wrapper .modal h3 {
    font-family: Georgia, Serif;
    font-size: 36px;
}

.modalContainer {
    width: 95%;
    top: 5%;
    right: 0;
    height: 85%;
    display: flex;
    left: 0;
    margin: auto;
}


@media only screen and (max-width: 768px) {
    .ty-wrapper {
        min-height: 350px;
    }
    .ty-wrapper > .modalContainer > div.modal > div.modalPadding {
        margin: 20px;
    }
}