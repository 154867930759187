/**  Float Label Test **/
.has-float-label {
  display: block;
  position: relative;
  border: 1px solid rgba(22,21,19,0.3);
  border-radius: 4px;
  height: 56px;
  margin: 30px auto 0;

}
.has-float-label label, .has-float-label span.inputName {
  position: absolute;
  left: 16px;
  top: 8px;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all .2s;
  transition: all .2s;
  color: var(--blockquote-color, rgba(22, 21, 19, 0.6));
}
.has-float-label select {
  display: block;
  line-height: 1.3;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--pre-bg-color, #fff);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid red;
  position:relative;

}

.has-float-label input, .has-float-label select {
  font-size: inherit;
  margin: 22px 0 12px 16px;
  border: 0;
  border-radius: 0;
  color: var(--main-form-color, #161513);
  width: 92%;
  box-shadow:none;
  background-color: var(--pre-bg-color, #fff);
}
.has-float-label input::-moz-placeholder, .has-float-label select::-moz-placeholder {
  opacity: 1;
  -moz-transition: all .2s;
  transition: all .2s;
}

.has-float-label input:-ms-input-placeholder, .has-float-label select:-ms-input-placeholder,
.has-float-label input::-ms-input-placeholder, .has-float-label select::-ms-input-placeholder {
  opacity: 1;
  -ms-transition: all .2s;
  transition: all .2s;
}
.has-float-label input::-webkit-input-placeholder, .has-float-label select::-webkit-input-placeholder,
.has-float-label input::placeholder, .has-float-label select::placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.has-float-label input:placeholder-shown:not(:focus)::-webkit-input-placeholder, .has-float-label select:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-float-label input:placeholder-shown:not(:focus)::-moz-placeholder, .has-float-label select:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}
.has-float-label input:placeholder-shown:not(:focus):-ms-input-placeholder, .has-float-label select:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.has-float-label input:placeholder-shown:not(:focus)::-ms-input-placeholder, .has-float-label select:placeholder-shown:not(:focus)::-ms-input-placeholder {
  opacity: 0;
}
.has-float-label input:placeholder-shown:not(:focus)::placeholder, .has-float-label select:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.has-float-label input:placeholder-shown:not(:focus) + *, .has-float-label select:placeholder-shown:not(:focus) + * {
  font-size: 16px;
  top: 15px;
}
.has-float-label input:focus, .has-float-label select:focus {
  outline: none;
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow:none;
}
.has-float-label select {
  padding-right: 1em;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.5em bottom 0.25em;
  background-size: 8px 10px;
}

/** End Float Label Test **/

/**  Input Component **/
.inputText {
  margin: 5px;
}

.inputText .labelContainer {
  order: 2;
  position: relative;
  text-align: left;
  padding: 0;
  box-sizing: border-box;
}
.inputText label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  pointer-events: none;
  color: rgba(16,15,14,.6);
  padding: 0 16px;
  line-height: 56px;
}

.inputText.active label {
  padding: 8px 16px 0;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
}

.inputText input {
  font-size: 16px;
  border: 0;
  width: 100%;
  flex-grow: 1;
  min-width: 0;
  /* background: #fff; */
  padding: 24px 16px 12px;
  min-height: 56px;
  line-height: 20px;
  color: #1a1816;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;
  height: auto;
  box-shadow: none;
  order: 3;
}

.inputText .itemWrapper {
  display:flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.inputText input[type=number]::-webkit-inner-spin-button,
.inputText input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputText input[type=number] {
  -moz-appearance:textfield;
}

.inputText .required {
  font-size: 12px;
  color: rgba(16,15,14,.6);
  float: right;
}

.inputText .input_outerContainer {
  border: 1px solid rgba(22,21,19,0.3);
  border-radius: 4px;
}

.has-float-label .error {
  font-size: 12px;
  color: rgba(22, 21, 19, 0.6);
}
.oj-ux-ico-warning-s:before {
  font-family: redwoodicons;
  content:'\f3a3';
  color: var(--main-bg-warning, #AA643B);
  float:left;
  margin: 2px 2px 0 0;
}

.hide {
  display:none;
}

/**  Button Component **/
button {
  color: #fcfbfa;
  font-size: 14px;
  height: 44px;
  min-width: 88px;
  margin: 32px 0;
  padding: 13px 20px 15px;
  border-radius: 4px;
  border: none;
}

button.callToAction {
  background: #6a9d67;
}

.redBorder {
  border: 1px solid var(--main-bg-danger,#CA4936);
}


