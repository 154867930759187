

.passwd input {
    width: 75%;
    margin: 7px 0 0;
    padding-top: 14px;
    padding-left: 16px;
}

.passwd .ReactPasswordStrength {
    float:left;
    border: none;
    width:100%;
}

.passwd .ReactPasswordStrength-strength-bar {
    height: 3px;
    border-radius: 0 0 4px 4px;
}

.passwd .ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-bar  {
    background: #C0533F;
}

.passwd .ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar  {
    background: #446672;
}

.passwd .ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar  {
    background: #24887E;
}
.passwd .ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar  {
    background: #4C825C;
}


.passwd .ReactPasswordStrength-input {
    padding: 15px 0 10px 14px;
    font-size: 16px;
}

.passwd .ReactPasswordStrength-input :not(:focus).is-password-invalid {
    color: var(--pre-bg-color, red);
}

.passwd .ReactPasswordStrength-strength-desc {
    right: 50px;
    top: 8px;
    font-size: 12px;
}

.toggleBtn {
    float: right;
    width: 25px;
    padding: 14px 18px;
    position: absolute;
    right: 0;
    z-index: 5;
    top:0;
}

.toggleBtn:before {
    font-family: redwoodicons;
    color: var(--html-txt-color, #100F0E);
    font-size: 150%;
    text-align: center;
    cursor: pointer;
}

.hideEyeIcon:before {
    content: '\f39a';
}

.showEyeIcon:before {
    content: '\f39b';
}

.greenCheckIcon:before {
    font-family: redwoodicons;
    content: '\f1bb';
    text-align: center;
    color: var(--main-bg-success, #5F7D4F);
}

.grayCheckIcon:before {
    font-family: redwoodicons;
    content: '\f1bb';
    text-align: center;
    color: var(--main-bg-slateGray, #DFDCD8);
}